import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import addloginstyles from "./DoctCollectedTablestyling.module.css";
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Dialog,
  DialogActions
} from "@mui/material";
import CenteredCircularProgress from "../../../Helpher/Loading";
import AddLoginDetailsForm from "./AddLoginDetailsForm";
import Api from "../../../Api";

const AddLoginDetailsList = ({ examType }) => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        setLoading(true);
        const response = await Api.getPenddingStudents(
          examType,
          page + 1,
          rowsPerPage
        );
        setStudents(response.data.studentsData);
        setTotalRecords(response.data.totalStudents);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudents();
  }, [page, rowsPerPage]);

  const handleOpenModal = (student) => {
    setSelectedStudent(student);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    window.location.reload();
    setSelectedStudent(null);
  };

  if (loading) return <CenteredCircularProgress />;

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page whenever rows per page changes
  };

  const filteredData =
    students && students.length > 0
      ? students.filter((student) =>
          student.nameOfApplicant
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      : [];
  console.log(examType);
  return (
    <Container className={addloginstyles.container}>
      <TableContainer
        component={Paper}
        className={addloginstyles.tableContainer}
      >
        <Table>
          <TableHead style={{ backgroundColor: "#FFF4DE", position: "sticky" }}>
            <TableRow className={addloginstyles.tableStyle}>
              <TableCell>ID</TableCell>
              <TableCell>Name of Applicant</TableCell>
              <TableCell>Father's Name</TableCell>
              <TableCell>Hall Ticket No.</TableCell>
              <TableCell>Rank</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>With Reference Of</TableCell>
              <TableCell>Add Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={9}
                  align="center"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "red"
                  }}
                >
                  No records found
                </TableCell>
              </TableRow>
            ) : (
              filteredData.map((student) => (
                <TableRow
                  key={student.id}
                  className={addloginstyles.tableStyle}
                >
                  <TableCell
                    style={{ color: "rgb(0, 120, 215)", fontWeight: "700" }}
                  >
                    {student.id}
                  </TableCell>
                  <TableCell
                    style={{ color: "rgb(52, 71, 103)", fontWeight: "700" }}
                  >
                    {student.nameOfApplicant}
                  </TableCell>
                  <TableCell>{student.fatherName}</TableCell>
                  <TableCell>
                    {student.qualifyingDetails[`${examType}HallTicketNo`]}
                  </TableCell>
                  <TableCell>
                    {student.qualifyingDetails[`${examType}Rank`]}
                  </TableCell>
                  <TableCell>{student.phoneNumber}</TableCell>
                  <TableCell>{student.category}</TableCell>
                  <TableCell>{student.withReferenceOf || "N/A"}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      style={{ fontSize: "0.6rem" }}
                      onClick={() => handleOpenModal(student)}
                    >
                      Click
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={handleRowsPerPageChange}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} of ${count}`
        }
        style={{ position: "sticky", bottom: "0", margin: "1rem" }}
      />
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
        {selectedStudent && (
          <AddLoginDetailsForm studentId={selectedStudent.id} />
        )}
      </Dialog>
    </Container>
  );
};

export default AddLoginDetailsList;
