import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const SemiCircleProgressBar = ({ percentage }) => {
  return (
    <div style={{ width: "300px", height: "100px", marginTop: "10px" }}>
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        styles={buildStyles({
          rotation: 0.75, // Rotate the path 270 degrees to start at the top
          strokeLinecap: "butt",
          trailColor: "#d6d6d6",
          pathColor: "#3e98c7",
          textColor: "#fff",
          pathTransitionDuration: 0.5,
        })}
        strokeWidth={10}
        circleRatio={0.5} // Half circle
      />
    </div>
  );
};

export default SemiCircleProgressBar;
