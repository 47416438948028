import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Typography,
  CircularProgress // Import CircularProgress
} from "@mui/material";
import Api from "../../Api";

const getColorBasedOncount = (count) => {
  if (count > 95) return { backgroundColor: "#00e676", color: "#00c853" }; // green
  if (count > 75) return { backgroundColor: "#64b5f6", color: "#1e88e5" }; // blue
  if (count > 60) return { backgroundColor: "#fff176", color: "#fbc02d" }; // yellow
  if (count > 30) return { backgroundColor: "#ffb74d", color: "#fb8c00" }; // orange
  return { backgroundColor: "#e57373", color: "#d32f2f" }; // red
};

const Performance = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    Api.getTopPerformance()
      .then((response) => {
        console.log("response", response);
        setData(response.data); // Update state with the data from API
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false if there is an error
      });
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          padding: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
        style={{ backgroundColor: "white" }}
      >
        <CircularProgress /> {/* Render CircularProgress while loading */}
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2 }} style={{ backgroundColor: "white" }}>
      <Typography variant="h6" component="h2" style={{ fontWeight: "bold" }}>
        Top Performance
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Performance</TableCell>
              <TableCell>Percentage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => {
              const { backgroundColor, color } = getColorBasedOncount(
                item.count
              );
              return (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Box width="100%" mr={1}>
                        <LinearProgress
                          variant="determinate"
                          value={item.count}
                          sx={{
                            height: "0.4rem",
                            borderRadius: "20px",
                            width: "10.8rem",
                            backgroundColor: backgroundColor,
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: color
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={`${item.count}%`}
                      variant="outlined"
                      sx={{
                        color: color,
                        borderRadius: "10px",
                        textAlign: "center",
                        border: `1px solid ${color}`
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Performance;
