import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Wakinstyles from "./commontablestyling.module.css";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination
} from "@mui/material";
import Api from "../../../Api";
import CenteredCircularProgress from "../../../Helpher/Loading";

const WalkInsStudents = ({ examType }) => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const location = useLocation(); // Hook to track URL changes

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        setLoading(true);
        let response;

        response = await Api.getWalkInsStudents(
          examType,
          page + 1,
          rowsPerPage
        );
        setStudents(response.data.studentsData);
        setTotalRecords(response.data.totalRecords);
        // }
        //  else if (examType?.toUpperCase() === "ECET") {
        //   response = await Api.getEcetWalkInsStudents(page + 1, rowsPerPage);
        //   setStudents(response.data.studentsData);
        //   setTotalRecords(response.data.totalRecords);
        // } else if (examType?.toUpperCase() === "ICET") {
        //   response = await Api.getIcetWalkInsStudents(page + 1, rowsPerPage);
        //   setStudents(response.data.studentsData);
        //   setTotalRecords(response.data.totalRecords);
        // } else if (examType?.toUpperCase() === "PGCET") {
        //   response = await Api.getPgcetWalkInsStudents(page + 1, rowsPerPage);
        //   setStudents(response.data.studentsData);
        //   setTotalRecords(response.data.totalRecords);
        // }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStudents(); // Initial fetch when component mounts
  }, [page, rowsPerPage, examType, location]); // Dependency array includes location to trigger on URL change

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0); // Reset to the first page whenever rows per page changes
  };

  const filteredData =
    students && students.length > 0
      ? students.filter((student) =>
          student.nameOfApplicant
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      : [];

  if (loading) return <CenteredCircularProgress />;

  return (
    <Container className={Wakinstyles.container}>
      <TableContainer component={Paper} className={Wakinstyles.tableContainer}>
        <Table>
          <TableHead style={{ backgroundColor: "#FFE2E5", position: "sticky" }}>
            <TableRow className={Wakinstyles.tableStyle}>
              <TableCell>ID</TableCell>
              <TableCell>Name of Applicant</TableCell>
              <TableCell>Father's Name</TableCell>
              <TableCell>Hall Ticket No.</TableCell>
              <TableCell>Rank</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>With Reference Of</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={8}
                  align="center"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "red"
                  }}
                >
                  No records found
                </TableCell>
              </TableRow>
            ) : (
              filteredData.map((student) => (
                <TableRow key={student.id} className={Wakinstyles.tableStyle}>
                  <TableCell style={{ color: "rgb(0, 120, 215)" }}>
                    {student.id}
                  </TableCell>
                  <TableCell style={{ color: "rgb(52, 71, 103)" }}>
                    {student.nameOfApplicant}
                  </TableCell>
                  <TableCell>{student.fatherName}</TableCell>
                  <TableCell>
                    <TableCell>
                      {student.qualifyingDetails[`${examType}HallTicketNo`]}
                    </TableCell>
                  </TableCell>
                  <TableCell>
                    {student.qualifyingDetails[`${examType}Rank`]}
                  </TableCell>
                  <TableCell>{student.phoneNumber}</TableCell>
                  <TableCell>{student.category}</TableCell>
                  <TableCell>{student.withReferenceOf || "N/A"}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} of ${count}`
          }
          style={{ position: "sticky", bottom: "0", margin: "1rem" }}
        />
      </div>
    </Container>
  );
};

export default WalkInsStudents;
