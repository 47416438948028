import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  CircularProgress
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./addlogindetails.module.css";
import Api from "../../../Api";

function AddLoginDetailsForm({ studentId }) {
  const [formData, setFormData] = useState({
    name: "",
    password: "",
    mobileNumber: "",
    rank: "",
    fatherName: "",
    caste: "",
    hallticket: "",
    loginId: "",
    rocNumber: "",
    reference: "",
    slotDate: "",
    dateOfBirth: ""
  });

  const [loading, setLoading] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (studentId) {
      fetchStudentDetails();
    }
  }, [studentId]);

  const fetchStudentDetails = async () => {
    setLoading(true);
    try {
      const response = await Api.getStudentsDetailsById(studentId);
      const studentData = response.data;

      setFormData({
        name: studentData.nameOfApplicant || "",
        password: studentData.password || "",
        mobileNumber: studentData.phoneNumber || "",
        mobileNumber1: studentData.phoneNumber1 || "",
        rank: studentData.qualifyingDetails?.EAPCETRank || "",
        fatherName: studentData.fatherName || "",
        caste: studentData.category || "",
        hallticket: studentData.qualifyingDetails?.EAPCETHallTicketNo || "",
        loginId: studentData.loginId || "",
        rocNumber: studentData.rocNumber || "",
        reference: studentData.withReferenceOf || "",
        slotDate: studentData.slotDate || "",
        dateOfBirth: studentData.dateOfBirth || ""
      });
    } catch (error) {
      console.error("Failed to fetch student details", error);
      // setError("Failed to fetch student details");
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingPost(true);

    try {
      const response = await Api.addLoginDetails(studentId, formData);
      if (response.success) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error(error.response?.message || "Failed to submit form");
    } finally {
      setLoadingPost(false);
    }
  };

  return (
    <Paper sx={{ padding: 3, width: "100%" }}>
      <ToastContainer />
      {loading ? (
        <CircularProgress />
      ) : (
        <form onSubmit={handleSubmit}>
          <Typography
            variant="h6"
            style={{
              textAlign: "center",
              fontWeight: "bold",
              paddingBottom: "0.2rem"
            }}
          >
            Add Login Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="name"
                label="Name"
                name="name"
                type="text"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="fatherName"
                label="Father's Name"
                name="fatherName"
                type="text"
                value={formData.fatherName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="caste"
                label="Caste"
                name="caste"
                type="text"
                value={formData.caste}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="reference"
                label="Reference"
                name="reference"
                type="text"
                value={formData.reference}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="slotDate"
                name="slotDate"
                label="Slot Date"
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={formData.slotDate}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="rocNumber"
                label="ROC Number"
                name="rocNumber"
                type="text"
                value={formData.rocNumber}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="hallticket"
                label="Hall Ticket"
                name="hallticket"
                type="text"
                value={formData.hallticket}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="rank"
                label="Rank"
                name="rank"
                type="number"
                value={formData.rank}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="dateOfBirth"
                name="dateOfBirth"
                label="Date of Birth"
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={formData.dateOfBirth}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="loginId"
                label="Login ID"
                name="loginId"
                type="text"
                value={formData.loginId}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="password"
                label="Password"
                name="password"
                type="text"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <small>Enter Your login Password</small>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="mobileNumber"
                label="Mobile Number"
                name="mobileNumber"
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                value={formData.mobileNumber}
                onChange={handleChange}
                required
              />
              <small>Format: xxx-xxx-xxxx</small>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="mobileNumber1"
                label="Alternate Number"
                name="mobileNumber1"
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                value={formData.mobileNumber1}
                onChange={handleChange}
                required
              />
              <small>Format: xxx-xxx-xxxx</small>
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            sx={{
              mt: 3,
              mb: 2,
              width: "15%",
              height: 30,
              "&.MuiButton-outlined": {
                borderColor: "primary.main",
                color: "primary.main",
                backgroundColor: "transparent",
                "&:hover": {
                  borderColor: "primary.dark",
                  color: "primary.dark",
                  backgroundColor: "#C5F1F4 "
                }
              }
            }}
            disabled={loadingPost}
          >
            {loadingPost ? <CircularProgress size={24} /> : "Submit"}
          </Button>

          {error && <Typography color="error">{error}</Typography>}
        </form>
      )}
    </Paper>
  );
}

export default AddLoginDetailsForm;
