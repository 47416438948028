import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "../Components/Dashboard/Sidebar";
import WalkInsStudents from "../Components/Dashboard/Eapcet/WalkInsStudents";
import AddLoginDetailsList from "../Components/Dashboard/Eapcet/AddLoginDetailsList";
import LoginDataStudentList from "../Components/Dashboard/Eapcet/LoginDataStudentList";
import CertificateCollectedList from "../Components/Dashboard/Eapcet/CertificateCollectedList";
import NavigationBar from "../Components/Dashboard/NavigationBar";
import Todays from "../Components/Dashboard/Todays";
import appstyles from "./approute.module.css";
import CustomTable from "../Components/Settings/CustomTable";
import DashboardOverviews from "../Components/Dashboard/DashboardOverviews";
import ProjectG from "../Components/Settings/ProjectG";
import Notification from "../Components/Dashboard/Notification";
import SignUp from "../Components/Dashboard/SignUp";
import { MenuProvider } from "../Components/Dashboard/MenuContext";
import SuperAdmin from "../Components/Dashboard/SuperAdmin/SuperAdmin";
import SemiCircleProgressBar from "../Components/Dashboard/SemiCircleProgressBar";
import EmployeeSuccessResponse from "../Components/Dashboard/SuperAdmin/SuccessResponse/EmployeeSuccessResponse";
import AdminLoginPage from "../Auth/AdminLoginPage";

export default function AppRoutes() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("access")
  );
  const [percentage, setPercentage] = useState(20);
  return (
    <Router>
      <div style={{ display: "flex" }}>
        <MenuProvider>
          {isAuthenticated ? (
            <>
              <Sidebar />
              <div className={appstyles.navRoute}>
                <NavigationBar />
                <Routes>
                  <Route path="/settings" element={<ProjectG />}>
                    <Route path="notification" element={<Notification />} />
                    <Route index element={<SignUp />} />
                    <Route path="signup" element={<SignUp />} />
                  </Route>

                  <Route path="/eapcet" element={<Todays examType="EAPCET" />}>
                    <Route
                      index
                      element={<WalkInsStudents examType="EAPCET" />}
                    />
                    <Route
                      path="walkIns-students-list"
                      element={<WalkInsStudents examType="EAPCET" />}
                    />
                    <Route
                      path="add-Login-details-list"
                      element={<AddLoginDetailsList examType="EAPCET" />}
                    />
                    <Route
                      path="logged-in-details-list"
                      element={<LoginDataStudentList examType="EAPCET" />}
                    />
                    <Route
                      path="certificate-collected-list"
                      element={<CertificateCollectedList examType="EAPCET" />}
                    />
                  </Route>

                  <Route path="/ecet" element={<Todays examType="ECET" />}>
                    <Route
                      index
                      element={<WalkInsStudents examType="ECET" />}
                    />
                    <Route
                      path="walkIns-students-list"
                      element={<WalkInsStudents examType="ECET" />}
                    />
                    <Route
                      path="add-Login-details-list"
                      element={<AddLoginDetailsList examType="ECET" />}
                    />
                    <Route
                      path="logged-in-details-list"
                      element={<LoginDataStudentList examType="ECET" />}
                    />
                    <Route
                      path="certificate-collected-list"
                      element={<CertificateCollectedList examType="ECET" />}
                    />
                  </Route>

                  <Route path="/pgcet" element={<Todays examType="PGCET" />}>
                    <Route
                      index
                      element={<WalkInsStudents examType="PGCET" />}
                    />
                    <Route
                      path="walkIns-students-list"
                      element={<WalkInsStudents examType="PGCET" />}
                    />
                    <Route
                      path="add-Login-details-list"
                      element={<AddLoginDetailsList examType="PGCET" />}
                    />
                    <Route
                      path="logged-in-details-list"
                      element={<LoginDataStudentList examType="PGCET" />}
                    />
                    <Route
                      path="certificate-collected-list"
                      element={<CertificateCollectedList examType="PGCET" />}
                    />
                  </Route>

                  <Route path="/icet" element={<Todays examType="ICET" />}>
                    <Route
                      index
                      element={<WalkInsStudents examType="ICET" />}
                    />
                    <Route
                      path="walkIns-students-list"
                      element={<WalkInsStudents examType="ICET" />}
                    />
                    <Route
                      path="add-Login-details-list"
                      element={<AddLoginDetailsList examType="ICET" />}
                    />
                    <Route
                      path="logged-in-details-list"
                      element={<LoginDataStudentList examType="ICET" />}
                    />
                    <Route
                      path="certificate-collected-list"
                      element={<CertificateCollectedList examType="ICET" />}
                    />
                  </Route>

                  <Route path="/dashboard" element={<DashboardOverviews />} />
                  <Route path="/super-admin" element={<SuperAdmin />}>
                    <Route
                      path="employee-response"
                      element={<EmployeeSuccessResponse />}
                    />
                  </Route>

                  <Route path="/leaderboard" element={<CustomTable />} />
                  <Route
                    path="/semi"
                    element={<SemiCircleProgressBar percentage={percentage} />}
                  />
                  <Route path="*" element={<div>Page Not Found</div>} />
                </Routes>
              </div>
            </>
          ) : (
            <Routes>
              <Route
                path="/admin-login"
                element={
                  <AdminLoginPage setIsAuthenticated={setIsAuthenticated} />
                }
              />
              <Route
                path="*"
                element={
                  <AdminLoginPage setIsAuthenticated={setIsAuthenticated} />
                }
              />
            </Routes>
          )}
        </MenuProvider>
      </div>
    </Router>
  );
}
