import { HttpService } from "./http.service";

const accessToken = localStorage.getItem("access");
const refreshToken = localStorage.getItem("refreshToken");

const addEmployee = async (formData) => {
  try {
    const response = await HttpService.post(
      `/api/v1/user/admin/add-employees`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const removeEmployee = async (id) => {
  try {
    const response = await HttpService.delete(
      `/api/v1/user/admin/remove-employee-by-id/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getAllEmployeesList = async () => {
  try {
    const response = await HttpService.get(
      `/api/v1/user/admin/get-all-employees`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getAllEmployees = async (formData) => {
  try {
    const response = await HttpService.get(
      `/api/v1/user/admin/get-all-employees`,
      formData
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getStudentsDetailsById = async (studentId) => {
  try {
    const response = await HttpService.get(
      `/api/v1/admin/get-student-details-by-id/${studentId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const updateEmployee = async (id, name, phoneNumber) => {
  try {
    const response = await HttpService.put(
      `/api/v1/user/admin/update-employee-by-id`,
      { id, name, phoneNumber },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getTotalConfirmation = async () => {
  try {
    const response = await HttpService.get(
      `/api/v1/user/admin/total-doc-submitted`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getEapcetStudentsList = async () => {
  try {
    const response = await HttpService.get(
      "/api/v1/user/admin/allType-students-data/EAPCET",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const addEapcetDocuments = async (formData) => {
  try {
    const response = await HttpService.post(
      "/api/v1/user/admin/add-student-documents",
      formData,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

const removeEapcetDocuments = async (studentId) => {
  try {
    const response = await HttpService.delete(
      `/api/v1/user/admin/eapcet-documents-delete/${studentId}`,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
const getStudentDocumentsDetails = async (id) => {
  try {
    const response = await HttpService.get(
      `/api/v1/user/admin/student-documents-by-student-id/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const adminLogin = async (email, password) => {
  try {
    const response = await HttpService.post(
      `/api/v1/admin/user-login`,
      {
        email,
        password
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const updateStudentDetails = async (studentId, formData) => {
  try {
    const response = await HttpService.put(
      `/api/v1/user/admin/update-student/${studentId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const deleteStudentById = async (studentId) => {
  try {
    const response = await HttpService.delete(
      `/api/v1/user/admin/delete-student/${studentId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getStudentsDetailsListByCategory = async (selectedCategory) => {
  try {
    const response = await HttpService.get(
      `/api/v1/user/admin/allType-students-data/${selectedCategory}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getSearchedStudents = async (searchTerm) => {
  try {
    const response = await HttpService.get(
      `/api/v1/user/admin/search-student/${searchTerm}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const addAdmin = async (formData) => {
  try {
    const response = await HttpService.post(
      `/api/v1/admin/create-admin`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

const getDocumentsSubmittedStudents = async (examType, page, limit) => {
  try {
    const response = await HttpService.get(
      `/api/v1/user/admin/${examType}/documents-submitted-data`,
      {
        params: { examType, page, limit },
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getWalkInsStudents = async (examType, page, limit) => {
  try {
    const response = await HttpService.get(
      `/api/v1/user/admin/:${examType}/walk-ins-students/`,
      {
        params: { examType, page, limit },
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching students:", error.response || error.message);
    throw error;
  }
};

const getPenddingStudents = async (examType, page, limit) => {
  try {
    const response = await HttpService.get(
      `/api/v1/user/admin/:${examType}/login-pendding-students`,
      {
        params: { examType, page, limit },
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getLoggedinStudents = async (examType, page) => {
  try {
    const response = await HttpService.get(
      `/api/v1/user/admin/${examType}/loggedin-students/`,
      {
        params: { examType, page },
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const addLoginDetails = async (studentId, formData) => {
  try {
    const response = await HttpService.post(
      `/api/v1/user/admin/add-login-data/${studentId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const updateEapcetDoc = async (studentID, formData) => {
  try {
    const response = await HttpService.put(
      `/api/v1/user/admin/update-eapcet-doc/${studentID}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getOverviewCount = async (examType) => {
  try {
    const response = await HttpService.get(
      `/api/v1/user/admin/dashboard/overview-count/${examType}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const updateEapcetStudent = async (studentId, formData) => {
  try {
    const response = await HttpService.put(
      `/api/v1/user/admin/update-student/${studentId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getBarChartData = async () => {
  try {
    const response = await HttpService.get(
      `api/v1/user/admin/total-performance/day-wise?startDate=2024-06-20&endData=24-07-05`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getTopPerformance = async () => {
  try {
    const response = await HttpService.get(
      `api/v1/user/admin/top-performance`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getUnreadNotifications = async () => {
  try {
    const response = await HttpService.get(
      `api/v1/user/admin/notifications/unread`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getAllNotifications = async () => {
  try {
    const response = await HttpService.get(`/api/v1/user/admin/notifications`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const removeNotification = async (id) => {
  try {
    const response = await HttpService.delete(
      `/api/v1/user/admin/notifications/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
const readNotificaion = async (id) => {
  try {
    const response = await HttpService.put(
      `/api/v1/user/admin/notifications/${id}/read`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
  } catch (error) {
    throw error.response.data;
  }
};

const totalUnreadNotificatrions = async () => {
  try {
    const response = await HttpService.get(
      `/api/v1/user/admin/notifications-count/unread`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
export default {
  addEmployee,
  removeEmployee,
  getAllEmployeesList,
  getAllEmployees,
  getStudentsDetailsById,
  updateEmployee,
  getTotalConfirmation,
  getEapcetStudentsList,
  addEapcetDocuments,
  removeEapcetDocuments,
  getStudentDocumentsDetails,
  adminLogin,
  updateStudentDetails,
  deleteStudentById,
  getStudentsDetailsListByCategory,
  getSearchedStudents,
  addAdmin,
  getDocumentsSubmittedStudents,
  getWalkInsStudents,
  getPenddingStudents,
  addLoginDetails,
  getLoggedinStudents,
  updateEapcetDoc,
  getOverviewCount,
  updateEapcetStudent,
  getBarChartData,
  getTopPerformance,
  getUnreadNotifications,
  getAllNotifications,
  removeNotification,
  readNotificaion,
  totalUnreadNotificatrions
};
