import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import certificatestyles from "./DoctCollectedTablestyling.module.css";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination
} from "@mui/material";

import CenteredCircularProgress from "../../../Helpher/Loading";
import api from "../../../Api/index";

const CertificateCollectedList = ({ examType }) => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        setLoading(true);
        const response = await api.getDocumentsSubmittedStudents(
          examType,
          page + 1,
          rowsPerPage
        );
        setStudents(response.data.studentsData);
        setTotalRecords(response.data.totalStudents);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudents();
  }, [page, rowsPerPage]);

  if (loading) return <CenteredCircularProgress />;

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData =
    students && students.length > 0
      ? students.filter((student) =>
          student.nameOfApplicant
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      : [];

  return (
    <Container className={certificatestyles.container}>
      <TableContainer
        component={Paper}
        className={certificatestyles.tableContainer}
      >
        <Table>
          <TableHead
            style={{
              height: "10%",
              backgroundColor: "#DCFCE7",
              position: "sticky"
            }}
          >
            <TableRow className={certificatestyles.tableStyle}>
              <TableCell>ID</TableCell>
              <TableCell>Name of Applicant</TableCell>
              <TableCell>Father's Name</TableCell>
              <TableCell>Hall Ticket No.</TableCell>
              <TableCell>Rank</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Alternate Number</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>With Reference</TableCell>
              <TableCell>Doc Date</TableCell>
              <TableCell>Documents</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={11}
                  align="center"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "red"
                  }}
                >
                  No records found
                </TableCell>
              </TableRow>
            ) : (
              filteredData.map((student) => (
                <TableRow
                  key={student.id}
                  className={certificatestyles.tableStyle}
                >
                  <TableCell
                    style={{ color: "rgb(0, 120, 215)", fontWeight: "700" }}
                  >
                    {student.id}
                  </TableCell>
                  <TableCell
                    style={{ color: "rgb(52, 71, 103)", fontWeight: "700" }}
                  >
                    {student.nameOfApplicant}
                  </TableCell>
                  <TableCell>{student.fatherName}</TableCell>
                  <TableCell>
                    {student.qualifyingDetails[`${examType}HallTicketNo`]}
                  </TableCell>
                  <TableCell>
                    {student.qualifyingDetails[`${examType}Rank`]}
                  </TableCell>
                  <TableCell>{student.phoneNumber}</TableCell>
                  <TableCell>{student.phoneNumber1}</TableCell>
                  <TableCell>{student.category}</TableCell>
                  <TableCell>{student.withReferenceOf || "N/A"}</TableCell>
                  <TableCell>{student.docSubmittedDate}</TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    {student.studentsOriginalDoc &&
                    Object.keys(student.studentsOriginalDoc).length > 0
                      ? Object.keys(student.studentsOriginalDoc).join(", ")
                      : "Only Xerox"}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={handleRowsPerPageChange}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} of ${count}`
        }
        style={{ position: "sticky", bottom: "0" }}
      />
    </Container>
  );
};

export default CertificateCollectedList;
