import React from "react";
import { Box, Button, Divider } from "@mui/material";
import { useNavigate, Outlet } from "react-router-dom";

const ProjectG = () => {
  const navigate = useNavigate();

  const handleTopNotification = () => {
    navigate("/settings/notification");
  };

  const handleSignUp = () => {
    navigate("/settings/signup");
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "50%", gap: 2 }}>
        <Button
          variant="text"
          color="primary"
          onClick={handleSignUp}
          sx={{ mt: 2 }}
        >
          Profile
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={handleTopNotification}
          sx={{ mt: 2 }}
        >
          Notification
        </Button>
      </Box>
      <Divider sx={{ my: 2, borderBottom: "2px solid #b6bdb6" }} />

      <Outlet />
    </>
  );
};

export default ProjectG;
