import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import loginstyles from "./commontablestyling.module.css";
import {
  Button,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination
} from "@mui/material";

import api from "../../../Api/index";
import CenteredCircularProgress from "../../../Helpher/Loading";

const LoginDataStudentList = ({ examType }) => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(examType);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        setLoading(true);
        const response = await api.getLoggedinStudents(examType, page + 1);
        setStudents(response.data);
        setStudents(response.data.studentsData);
        setTotalRecords(response.data.totalStudents || 0);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudents();
  }, [selectedCategory]);

  if (loading) return <CenteredCircularProgress />;

  const filteredData =
    students && students.length > 0
      ? students.filter((student) =>
          student.nameOfApplicant
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      : [];

  return (
    <Container className={loginstyles.container}>
      <TableContainer component={Paper}>
        <Table sx={{ position: "sticky" }}>
          <TableHead style={{ backgroundColor: "#F3E8FF", position: "sticky" }}>
            <TableRow className={loginstyles.tableStyle}>
              <TableCell>ID</TableCell>
              <TableCell>Name of Applicant</TableCell>
              <TableCell>ROC Number</TableCell>
              <TableCell>Hall Ticket No.</TableCell>
              <TableCell>Rank</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>Password</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>With Reference Of</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={9}
                  align="center"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "red"
                  }}
                >
                  No records found
                </TableCell>
              </TableRow>
            ) : (
              filteredData.map((student) => (
                <TableRow key={student.id} className={loginstyles.tableStyle}>
                  <TableCell
                    style={{ color: "rgb(0, 120, 215)", fontWeight: " 700 " }}
                  >
                    {student.id}
                  </TableCell>
                  <TableCell
                    style={{ color: "rgb(52, 71, 103)", fontWeight: "700" }}
                  >
                    {student.nameOfApplicant}
                  </TableCell>
                  <TableCell>{student.loginDetails.ROC}</TableCell>
                  <TableCell>
                    {student.qualifyingDetails[`${examType}HallTicketNo`]}
                  </TableCell>
                  <TableCell>
                    {student.qualifyingDetails[`${examType}Rank`]}
                  </TableCell>
                  <TableCell>{student.dateOfBirth}</TableCell>
                  <TableCell>{student.loginDetails.password}</TableCell>
                  <TableCell>{student.phoneNumber}</TableCell>
                  <TableCell>{student.category}</TableCell>
                  <TableCell>{student.withReferenceOf || "N/A"}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalRecords}
          rowsPerPage={10}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          sx={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}
        />
      </TableContainer>
    </Container>
  );
};

export default LoginDataStudentList;
