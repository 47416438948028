import React, { useContext, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from "@mui/material";
import { useNavigate, Outlet } from "react-router-dom";
import { MenuContext } from "../Dashboard/MenuContext";
import dashboardicon from "../../Assets/sidebar/dashboardIcon.png";
import leaderboardicon from "../../Assets/sidebar/leaderboardicon.png";
import superadminicon from "../../Assets/sidebar/superadminicon.png";
import eapceticon from "../../Assets/sidebar/eapceticon.png";
import eceticon from "../../Assets/sidebar/eceticon.png";
import iceticon from "../../Assets/sidebar/iceticon.png";
import pgceticon from "../../Assets/sidebar/pgceticon.png";
import settingsicon from "../../Assets/sidebar/settingsicon.png";
import signouticon from "../../Assets/sidebar/signouticon.png";
import ysrLogo from "../../Assets/sidebar/YSR logo.png";
import axios from "axios";

const Sidebar = () => {
  const { setSelectedMenu } = useContext(MenuContext);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const icons = [
    dashboardicon,
    leaderboardicon,
    superadminicon,
    eapceticon,
    eceticon,
    iceticon,
    pgceticon,
    settingsicon,
    signouticon
  ];

  const handleClick = (text) => {
    if (text.toUpperCase() === "SIGN OUT") {
      setOpenDialog(true);
    } else {
      setSelectedMenu(text);
      switch (text.toUpperCase()) {
        case "DASHBOARD":
          navigate("/dashboard");
          break;
        case "LEADERBOARD":
          navigate("/leaderboard");
          break;
        case "SUPER ADMIN":
          navigate("/super-admin");
          break;
        case "EAPCET":
          navigate("/eapcet");
          break;
        case "ECET":
          navigate("/ecet");
          break;
        case "ICET":
          navigate("/icet");
          break;
        case "PGCET":
          navigate("/pgcet");
          break;
        case "SETTINGS":
          navigate("/settings");
          break;
        default:
          navigate("/");
          break;
      }
    }
  };

  const handleSignOut = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("selectedMenu");
    delete axios.defaults.headers.common["Authorization"];
    setOpenDialog(false);
    navigate("/admin-login");
    window.location.reload();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <Box
        sx={{
          width: { xs: "100%", sm: 310 },
          height: "982px",
          position: "fixed",
          overflow: "auto",
          display: "flex",
          flexDirection: "column"
        }}
        role="presentation"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
            marginTop: "20px",
            marginLeft: "30px"
          }}
        >
          <img
            src={ysrLogo}
            alt="YSR logo"
            style={{
              width: "56px",
              height: "56px",
              marginRight: "16px"
            }}
          />
          <span
            style={{
              fontFamily: "Poppins",
              fontSize: "30px",
              fontWeight: 600,
              lineHeight: "45px",
              textAlign: "left"
            }}
          >
            YSR ADMIN
          </span>
        </div>
        <List>
          {[
            "Dashboard",
            "Leaderboard",
            "Super Admin",
            "Eapcet",
            "Ecet",
            "Icet",
            "Pgcet",
            "Settings",
            "Sign Out"
          ].map((text, index) => (
            <ListItem
              button
              key={text}
              onClick={() => handleClick(text)}
              sx={{
                width: { xs: "100%", sm: "222px" },
                height: "44px",
                marginLeft: { xs: 0, sm: "30px" },
                marginBottom: "17px",
                borderRadius: "16px",
                boxShadow: "0px 12px 30px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  backgroundColor: "#2196f3"
                },
                "&:hover .MuiListItemText-primary": {
                  color: "white"
                },
                "&:hover .MuiListItemIcon-root img": {
                  filter: "brightness(0) invert(1)"
                },
                display: "flex",
                alignItems: "center",
                padding: "10px"
              }}
            >
              <ListItemIcon>
                <img
                  src={icons[index]}
                  alt={`${text.toLowerCase()} icon`}
                  style={{ width: 24, height: 24 }}
                />
              </ListItemIcon>
              <ListItemText primary={text} sx={{ color: "inherit" }} />
            </ListItem>
          ))}
        </List>
      </Box>
      <Outlet /> {/* Outlet to render nested routes */}
      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Sign Out"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to sign out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSignOut} color="primary" autoFocus>
            Sign Out
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Sidebar;
