import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Avatar, Typography } from "@mui/material";
import Api from "../../Api";

const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  // const [adminDetails, setAdminDetails] = useState();
  const adminDetails = {
    name: "Bhagya",
    email: "bhagya@gmail.com",
    phoneNumber: "7896541236"
  };
  // useEffect(() => {
  //   const fetchData = async (req, res) => {
  //     setLoading(true); 
  //     try {
  //       const response = Api.getAdminDetails();
  //       setAdminDetails(response.data);
  //     } catch (error) {
  //       console.log("//////////Error/////////", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchData();
  // }, []);
  const handleAdd = () => {};

  const handleEdit = () => {};

  const handleChangePassword = () => {};
  return (
    <div>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          p: 3,
          paddingLeft: 0
        }}
        noValidate
        autoComplete="off"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            mt: 2,
            width: "100%"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Avatar
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhz1SSNcaEBhlNtUwQCe0x0gpLLySGyCFkkQ&s"
              sx={{ width: 100, height: 100, mb: 2 }}
            />
          </Box>
          <Box
            style={{ textAlign: "left" }}
            sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 1.5 }}
          >
            <Box sx={{ display: "flex", width: "50%", gap: 2 }}>
              <Box sx={{ flex: 1, width: "25%" }}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", mb: 0.5 }}
                >
                  Your Name
                </Typography>
                <TextField
                  value={name}
                  placeholder={adminDetails.name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  sx={{ "& .MuiOutlinedInput-root": { borderRadius: "10px" } }}
                />
              </Box>
              <Box sx={{ flex: 1, width: "25%" }}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", mb: 0.5 }}
                >
                  Email
                </Typography>
                <TextField
                  value={email}
                  placeholder={adminDetails.email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  sx={{ "& .MuiOutlinedInput-root": { borderRadius: "10px" } }}
                />
              </Box>
            </Box>
            <Box sx={{ width: "25%" }}>
              <Typography variant="body2" sx={{ fontWeight: "bold", mb: 0.5 }}>
                Your Phone Number
              </Typography>
              <TextField
                value={phoneNumber}
                placeholder={adminDetails.phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                fullWidth
                sx={{ "& .MuiOutlinedInput-root": { borderRadius: "10px" } }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: 2,
                mt: 2
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleEdit}
                sx={{ borderRadius: "10px" }}
              >
                Edit
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleChangePassword}
                sx={{ borderRadius: "10px" }}
              >
                Change Password
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default SignUp;
