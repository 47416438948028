import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Popover from "@mui/material/Popover";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import "react-toastify/dist/ReactToastify.css";
import adminProfile from "../../Assets/navbar/Rectangle 1393.png";
import IND from "../../Assets/navbar/indiaFlag.png";
import navStyles from "../../css/navigationbar.module.css";
import { MenuContext } from "../Dashboard/MenuContext";
import Api from "../../Api";
import { toast } from "react-toastify";
import CenteredCircularProgress from "../../Helpher/Loading";
import noNotification from "./navbarAssets/no-notification.svg";

const CustomDropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 7L10 10L13 7"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </components.DropdownIndicator>
);

const adminOptions = [
  {
    value: "admin",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={adminProfile}
          alt="Admin profile"
          style={{ marginRight: 10, width: "44.46px", height: "45.5px" }}
        />
        <div>
          <h1 className={navStyles.admin}>Saddam</h1>
          <h4 className={navStyles.adminRole}>Admin</h4>
        </div>
      </div>
    )
  }
];

const countryOptions = [
  {
    value: "IND",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={IND}
          alt="IND flag"
          style={{ marginRight: 8, width: "24px", height: "24px" }}
        />
        <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>ENG(IND)</span>
      </div>
    )
  },
  {
    value: "CA",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={IND}
          alt="CA flag"
          style={{ marginRight: 8, width: "24px", height: "24px" }}
        />
        <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>ENG(CA)</span>
      </div>
    )
  },
  {
    value: "GB",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={IND}
          alt="GB flag"
          style={{ marginRight: 8, width: "24px", height: "24px" }}
        />
        <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>ENG(GB)</span>
      </div>
    )
  }
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "50px",
    border: "none",
    boxShadow: "none"
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    padding: "10px 15px",
    backgroundColor: state.isSelected ? "#f0f0f0" : "white",
    marginBottom: "5px",
    ":last-of-type": {
      marginBottom: 0
    }
  }),
  menu: (provided) => ({
    ...provided,
    border: "none",
    boxShadow: "none"
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center"
  }),
  indicatorSeparator: () => ({
    display: "none"
  }),
  dropdownIndicator: (provided) => ({
    ...provided
  })
};

const NavigationBar = () => {
  const { selectedMenu } = useContext(MenuContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const navigate = useNavigate();

  const fetchNotifications = async () => {
    try {
      setLoading(true);
      const response = await Api.getUnreadNotifications();
      setNotifications(response.data);
      toast.success("Notifications fetched successfully");
    } catch (error) {
      console.error("Failed to fetch notifications:", error);
      toast.error("Failed to fetch notifications");
    } finally {
      setLoading(false);
    }
  };

  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
    await fetchNotifications();
  };

  const fetchNotificationsOnLoad = async () => {
    await fetchNotifications();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowMore = async (index, id) => {
    await Api.readNotificaion(id);
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notification-popover" : undefined;

  const handleSeeAllNotifications = () => {
    handleClose();
    navigate("/settings/notification");
  };

  useEffect(() => {
    fetchNotificationsOnLoad();
  }, []);

  return (
    <div className={navStyles.navBox}>
      <div className={navStyles.title}>
        <h1>{selectedMenu || "Dashboard"}</h1>
      </div>

      <div className={navStyles.contentBox}>
        <div className={navStyles.inputBox}>
          <input
            className={navStyles.input}
            type="text"
            placeholder="Search here..."
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "10px",
              transform: "translateY(-50%)"
            }}
          >
            <FontAwesomeIcon icon={faSearch} color="#5D5FEF" />
          </div>
        </div>
        <div className={navStyles.countrydropdown}>
          <Select
            options={countryOptions}
            styles={customStyles}
            defaultValue={countryOptions[0]}
            components={{ DropdownIndicator: CustomDropdownIndicator }}
          />
        </div>
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <Badge badgeContent={notifications.length} color="secondary">
            <NotificationsNoneIcon
              style={{ color: "#FFA412" }}
              aria-describedby={id}
              onClick={handleClick}
            />
          </Badge>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            sx={{ marginTop: "0.05rem" }}
          >
            <div style={{ width: "50vh", height: "400px", overflowY: "auto" }}>
              {loading ? (
                <CenteredCircularProgress />
              ) : notifications.length === 0 ? (
                <Typography className={navStyles.typographyContainer}>
                  <div className={navStyles.typographyContent}>
                    No Notifications
                    <img src={noNotification} alt="No Notifications" />
                    <small>Clutter cleared! Nothing new to see here.</small>
                  </div>
                </Typography>
              ) : (
                <>
                  {notifications.map((notification, index) => {
                    const isExpanded = expandedIndex === index;
                    const bodyWords = notification.body.split(" ");
                    const shortBody = bodyWords.slice(0, 10).join(" ");
                    const fullBody = bodyWords.join(" ");

                    return (
                      <div
                        key={index}
                        style={{
                          paddingLeft: "1rem",
                          paddingRight: "0.4rem"
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "0.5rem 0"
                          }}
                        >
                          <h1
                            style={{
                              fontWeight: "bold",
                              fontSize: "0.8rem",
                              color: "#172BEF"
                            }}
                          >
                            {notification.title}
                          </h1>
                          <h6 style={{ fontSize: "0.67rem", color: "#FFA412" }}>
                            {notification.time}
                          </h6>
                        </div>
                        <div
                          style={{
                            margin: "0.4rem 0",
                            fontSize: "0.7rem",
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <span style={{ flex: "1" }}>
                            {isExpanded ? fullBody : shortBody}
                          </span>
                          {bodyWords.length > 10 && (
                            <button
                              onClick={() =>
                                handleShowMore(index, notification.id)
                              }
                              style={{
                                marginLeft: "0.5rem",
                                fontSize: "0.7rem",
                                color: "#172BEF",
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                textDecoration: "underline"
                              }}
                            >
                              {isExpanded ? "Show less" : "Show more"}
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "0.5rem",
                      padding: "0.5rem"
                    }}
                  >
                    <button
                      onClick={handleSeeAllNotifications}
                      style={{
                        fontSize: "0.67rem",
                        color: "#172BEF",
                        background: "none",
                        margin: 0,
                        border: "none",
                        cursor: "pointer",
                        textDecoration: "underline"
                      }}
                    >
                      See all notifications
                    </button>
                  </div>
                </>
              )}
            </div>
          </Popover>
        </div>
        <div className={navStyles.dropdown}>
          <Select
            options={adminOptions}
            styles={customStyles}
            defaultValue={adminOptions[0]}
            components={{ DropdownIndicator: CustomDropdownIndicator }}
          />
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
