import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import reality from "./Assets/reality.png";
import target from "./Assets/target.png";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import Api from "../../Api";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = () => {
  const [chartData, setChartData] = useState({
    labels: ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"],
    datasets: [
      {
        label: "Reality",
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: "#4AB58E",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        borderRadius: 7
      },
      {
        label: "Target",
        data: [40, 40, 40, 40, 40, 40, 40],
        backgroundColor: "yellow",
        borderColor: "rgba(153, 102, 255, 0)",
        borderWidth: 1,
        borderRadius: 7
      }
    ]
  });
  const [realityTotal, setRealityTotal] = useState(0);
  const [targetTotal, setTargetTotal] = useState(0);

  const handleGetDayName = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", { weekday: "long" });
  };

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = await Api.getBarChartData();
        const fetchedData = response.data;

        const daysArray = fetchedData.map((item) => ({
          ...item,
          day: handleGetDayName(item.date)
        }));
        const days = daysArray.map((item) => item.day.substring(0, 3));
        const dates = fetchedData.map((item) => item.date);
        const realityData = fetchedData.map((item) => item.count);

        const targetData = Array(realityData.length).fill(40);

        const totalReality = realityData.reduce((acc, val) => acc + val, 0);
        const totalTarget = targetData.reduce((acc, val) => acc + val, 0);

        setChartData({
          labels: days,
          datasets: [
            {
              label: "Reality",
              data: realityData,
              backgroundColor: "#4AB58E",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
              borderRadius: 7
            },
            {
              label: "Target",
              data: targetData,
              backgroundColor: "yellow",
              borderColor: "rgba(153, 102, 255, 0)",
              borderWidth: 1,
              borderRadius: 7
            }
          ]
        });

        setRealityTotal(totalReality);
        setTargetTotal(totalTarget);
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    };

    fetchChartData();
  }, []);

  const options = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        ticks: {
          display: false
        },
        grid: {
          display: false,
          drawBorder: false
        },
        border: {
          display: false,
          width: 0
        }
      },
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        border: {
          display: false,
          width: 0
        }
      }
    }
  };

  return (
    <div>
      <h1 style={{ fontWeight: "bold", width: "100%", marginLeft: "0.5rem" }}>
        Target Vs Reality
      </h1>
      <Bar data={chartData} options={options} width={100} height={36} />
      <div
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <img
          src={reality}
          alt="Reality Icon"
          style={{ width: "30px", marginLeft: "0.5rem", marginRight: "4px" }}
        />
        <p style={{ margin: 0, fontWeight: "bold", width: "100%" }}>
          <span style={{ color: "black" }}>Reality</span>
          <span style={{ color: "#4AB58E", marginLeft: "235px" }}>8.823</span>
        </p>
      </div>
      <h7 style={{ marginLeft: "42px", color: "#737791" }}>Global</h7>
      <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
        <img
          src={target}
          alt="Target Icon"
          style={{ width: "30px", marginLeft: "0.5rem", marginRight: "4px" }}
        />

        <p style={{ margin: 0, fontWeight: "bold", width: "100%" }}>
          <span style={{ color: "black" }}>Target </span>
          <span style={{ color: "#FFA412", marginLeft: "235px" }}>12.122</span>
        </p>
      </div>
      <h7 style={{ marginLeft: "42px", color: "#737791" }}>Commercial</h7>
    </div>
  );
};

export default BarChart;
