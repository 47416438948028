import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../Api";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

const AddEmployeeForm = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false); // Add error state
  const [dialogOpen, setDialogOpen] = useState(false); // State for dialog
  const [dialogMessage, setDialogMessage] = useState(""); // State for dialog message

  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber" && value.length > 10) {
      return;
    }

    setFormData({
      ...formData,
      [name]: value
    });

    // Set error state based on phone number length
    if (name === "phoneNumber") {
      setError(value.length !== 10);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    try {
      if (formData.phoneNumber.length !== 10) {
        throw new Error("Phone number must be 10 digits long");
      }

      const response = await api.addEmployee(formData);
      if (response.success === true) {
        setMessage(response.message);
        setDialogMessage(response.message || "Employee added successfully!");
        setDialogOpen(true);
      } else {
        console.log("Unexpecte1d API Response:", response);
        const errorData = response.data || {};
        setMessage(errorData.message || "Unknown error");
      }
    } catch (error) {
      console.error("API Request Failed:", error);
      setMessage("Phone number must be 10 digits long");
    } finally {
      setLoading(false);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    navigate("/super-admin/employee-response");
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 2 }}>
        <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
          Add Employee
        </Typography>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 2 }}>
              <TextField
                style={{ width: "70%" }}
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                helperText="Please enter the name of the employee"
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                sx={{ mb: 1 }}
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <TextField
                style={{ width: "70%", marginTop: "0.3rem" }}
                label="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
                error={error}
                helperText={
                  error ? "Phone number must be exactly 10 digits long" : ""
                }
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 10 }}
                sx={{ mb: 1 }}
              />
            </Box>

            <Box sx={{ mt: 1 }}>
              <Button type="submit" variant="contained" color="primary">
                Add Employee
              </Button>
            </Box>
          </form>
        )}
      </Box>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Success</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AddEmployeeForm;
