import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Api from "../../Api";
import { Delete } from "@mui/icons-material";
import CenteredCircularProgress from "../../Helpher/Loading";

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedNotifications, setExpandedNotifications] = useState({});
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await Api.getAllNotifications();
        if (isMounted) {
          setNotifications(response.data);
          toast.success("Notifications fetched successfully!");
        }
      } catch (error) {
        if (isMounted) {
          console.error(error);
          toast.error("Failed to fetch notifications.");
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleShowMore = (id) => {
    setExpandedNotifications((prev) => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this notification?"
    );
    if (!confirmed) return;

    setDeleting(true);
    try {
      await Api.removeNotification(id);
      setNotifications((prev) =>
        prev.filter((notification) => notification.id !== id)
      );
      toast.success("Notification deleted successfully!");
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete notification.");
    } finally {
      setDeleting(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div style={{ padding: "1rem", width: "50%" }}>
        {loading ? (
          <CenteredCircularProgress />
        ) : (
          notifications.map((notification) => {
            const words = notification.body.split(" ");
            const showMore = words.length > 10;
            const isExpanded = expandedNotifications[notification.id];

            return (
              <div
                key={notification.id}
                style={{
                  backgroundColor: notification.isUnread
                    ? "#f0f8ff"
                    : "inherit",
                  padding: "1rem",
                  margin: "0.5rem 0"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0.5rem 0"
                  }}
                >
                  <h1 style={{ fontWeight: "600" }}>{notification.title}</h1>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h6 style={{ fontSize: "0.8rem" }}>{notification.time}</h6>
                    <Delete
                      onClick={() => handleDelete(notification.id)}
                      style={{
                        color: "red",
                        cursor: "pointer",
                        marginLeft: "1rem"
                      }}
                    />
                  </div>
                </div>
                <div style={{ margin: "0.5rem 0", fontSize: "0.9rem" }}>
                  {isExpanded
                    ? notification.body
                    : words.slice(0, 10).join(" ")}
                  {showMore && (
                    <button
                      onClick={() => handleShowMore(notification.id)}
                      style={{
                        marginLeft: "0.5rem",
                        color: "blue",
                        background: "none",
                        border: "none",
                        cursor: "pointer"
                      }}
                    >
                      {isExpanded ? "Hide" : "Show More"}
                    </button>
                  )}
                </div>
                <hr />
              </div>
            );
          })
        )}
        {deleting && <CenteredCircularProgress />}
      </div>
    </div>
  );
};

export default Notification;
