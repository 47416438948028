import React, { useEffect, useState } from "react";
import styles from "../../css/todays.module.css";
import { Link, useLocation, Outlet } from "react-router-dom";
import api from "../../Api";
import walkInsIcon from "../../Assets/todays/Icon.png";
import icon1 from "../../Assets/todays/Icon (1).png";
import CollecteDocsIcon from "../../Assets/todays/Icon (2).png";
import icon3 from "../../Assets/todays/Icon (3).png";
import returnDocs from "../../Assets/todays/returnDocs.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IconButton } from "@mui/material";

export default function Todays({ examType }) {
  const [data, setData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const [show, setShow] = useState(true);

  useEffect(() => {
    // Fetch count only when the component loads
    const fetchCount = async () => {
      try {
        const response = await api.getOverviewCount(examType);
        const output = response.data || 0;
        setData(
          output.length <= 1
            ? {
                totalWalkIns: 0,
                totalCollectedDocs: 0,
                totalPendingLogIn: 0,
                totalLoggedIn: 0
              }
            : output
        );
      } catch (error) {
        console.error("Error fetching overview count:", error);
      }
    };

    fetchCount();
  }, []); // Dependency array is empty to ensure this runs only once on component mount

  const getPrice = (value) => {
    return value !== undefined && value !== null ? `${value}` : "0";
  };

  const contents = {
    eapcet: [
      {
        logo: walkInsIcon,
        price: getPrice(data?.totalWalkIns || 0),
        title: "Total Walkins",
        profit: "+8% from yesterday",
        path: "walkIns-students-list",
        colorClass: "yellow"
      },
      {
        logo: CollecteDocsIcon,
        price: getPrice(data?.totalCollectedDocs || 0),
        title: "Collected Docs",
        profit: "+8% from yesterday",
        path: "certificate-collected-list",
        colorClass: "green"
      },
      {
        logo: icon1,
        price: getPrice(data?.totalPendingLogIn || 0),
        title: "Add Login Details",
        profit: "+8% from yesterday",
        path: "add-Login-details-list",
        colorClass: "blue"
      },
      {
        logo: icon3,
        price: getPrice(data?.totalLoggedIn || 0),
        title: "Logged In Data",
        profit: "+8% from yesterday",
        path: "logged-in-details-list",
        colorClass: "pink"
      },
      {
        logo: returnDocs,
        price: 0,
        title: "Returned Docs",
        profit: "+8% from yesterday",
        path: "logged-in-details-list",
        colorClass: "purple"
      }
    ],
    ecet: [
      {
        logo: walkInsIcon,
        price: getPrice(data?.totalWalkIns || 0),
        title: "Total Walkins",
        profit: "+8% from yesterday",
        path: "walkIns-students-list",
        colorClass: "yellow"
      },
      {
        logo: CollecteDocsIcon,
        price: getPrice(data?.totalCollectedDocs || 0),
        title: "Collected Docs",
        profit: "+8% from yesterday",
        path: "certificate-collected-list",
        colorClass: "green"
      },
      {
        logo: icon1,
        price: getPrice(data?.totalPendingLogIn || 0),
        title: "Add Login Details",
        profit: "+8% from yesterday",
        path: "add-Login-details-list",
        colorClass: "blue"
      },
      {
        logo: icon3,
        price: getPrice(data?.totalLoggedIn || 0),
        title: "Logged In Data",
        profit: "+8% from yesterday",
        path: "logged-in-details-list",
        colorClass: "pink"
      },
      {
        logo: returnDocs,
        price: 0,
        title: "Returned Docs",
        profit: "+8% from yesterday",
        path: "logged-in-details-list",
        colorClass: "purple"
      }
    ],
    icet: [
      {
        logo: walkInsIcon,
        price: getPrice(data?.totalWalkIns || 0),
        title: "Total Walkins",
        profit: "+8% from yesterday",
        path: "walkIns-students-list",
        colorClass: "yellow"
      },
      {
        logo: CollecteDocsIcon,
        price: getPrice(data?.totalCollectedDocs || 0),
        title: "Collected Docs",
        profit: "+8% from yesterday",
        path: "certificate-collected-list",
        colorClass: "green"
      },
      {
        logo: icon1,
        price: getPrice(data?.totalPendingLogIn || 0),
        title: "Add Login Details",
        profit: "+8% from yesterday",
        path: "add-Login-details-list",
        colorClass: "blue"
      },
      {
        logo: icon3,
        price: getPrice(data?.totalLoggedIn || 0),
        title: "Logged In Data",
        profit: "+8% from yesterday",
        path: "logged-in-details-list",
        colorClass: "pink"
      },
      {
        logo: returnDocs,
        price: 0,
        title: "Returned Docs",
        profit: "+8% from yesterday",
        path: "logged-in-details-list",
        colorClass: "purple"
      }
    ],
    dashboard: [
      {
        logo: walkInsIcon,
        price: getPrice(data?.totalWalkIns || 0),
        title: "Total Walkins",
        profit: "+8% from yesterday",
        path: "walkIns-students-list",
        colorClass: "yellow"
      },
      {
        logo: CollecteDocsIcon,
        price: getPrice(data?.totalCollectedDocs || 0),
        title: "Collected Docs",
        profit: "+8% from yesterday",
        path: "certificate-collected-list",
        colorClass: "green"
      },
      {
        logo: icon1,
        price: getPrice(data?.totalPendingLogIn || 0),
        title: "Add Login Details",
        profit: "+8% from yesterday",
        path: "add-Login-details-list",
        colorClass: "blue"
      },
      {
        logo: icon3,
        price: getPrice(data?.totalLoggedIn || 0),
        title: "Logged In Data",
        profit: "+8% from yesterday",
        path: "logged-in-details-list",
        colorClass: "pink"
      },
      {
        logo: returnDocs,
        price: 0,
        title: "Returned Docs",
        profit: "+8% from yesterday",
        path: "logged-in-details-list",
        colorClass: "purple"
      }
    ],
    pgcet: [
      {
        logo: walkInsIcon,
        price: getPrice(data?.totalWalkIns || 0),
        title: "Total Walkins",
        profit: "+8% from yesterday",
        path: "walkIns-students-list",
        colorClass: "yellow"
      },
      {
        logo: CollecteDocsIcon,
        price: getPrice(data?.totalCollectedDocs || 0),
        title: "Collected Docs",
        profit: "+8% from yesterday",
        path: "certificate-collected-list",
        colorClass: "green"
      },
      {
        logo: icon1,
        price: getPrice(data?.totalPendingLogIn || 0),
        title: "Add Login Details",
        profit: "+8% from yesterday",
        path: "add-Login-details-list",
        colorClass: "blue"
      },
      {
        logo: icon3,
        price: getPrice(data?.totalLoggedIn || 0),
        title: "Logged In Data",
        profit: "+8% from yesterday",
        path: "logged-in-details-list",
        colorClass: "pink"
      },
      {
        logo: returnDocs,
        price: 0,
        title: "Returned Docs",
        profit: "+8% from yesterday",
        path: "logged-in-details-list",
        colorClass: "purple"
      }
    ]
  };

  useEffect(() => {
    let currentIndex;
    switch (true) {
      case location.pathname.includes("eapcet"):
        currentIndex = 0;
        break;
      case location.pathname.includes("ecet"):
        currentIndex = 1;
        break;
      case location.pathname.includes("icet"):
        currentIndex = 2;
        break;
      case location.pathname.includes("dashboard"):
        currentIndex = 3;
        break;
      case location.pathname.includes("pgcet"):
        currentIndex = 4;
        break;
      default:
        currentIndex = null;
    }
    setActiveIndex(currentIndex);
  }, [location.pathname]);

  const handleBoxClick = (index) => {
    setActiveIndex(index);
  };

  const handleInnerBoxViewDown = () => {
    setShow(!show);
  };

  const activeContent = contents[Object.keys(contents)[activeIndex]] || [];

  return (
    <div>
      <div className={styles.container}>
        {show && (
          <div className={styles.box}>
            {activeContent.map((content, index) => (
              <div
                className={`${styles.innerbox} ${styles[content.colorClass]} ${
                  index === activeIndex ? styles.active : ""
                }`}
                key={content.title}
                onClick={() => handleBoxClick(index)}
              >
                <Link to={content.path} className={styles.link}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <img
                        src={content.logo}
                        className={styles.logo}
                        alt={content.title}
                      />
                    </div>
                  </div>
                  <div className={styles.price}>{content.price}</div>
                  <div className={styles.title}>{content.title}</div>
                  <div className={styles.profit}>{content.profit}</div>
                </Link>
              </div>
            ))}
          </div>
        )}
        <button onClick={handleInnerBoxViewDown}>
          {show ? (
            <IconButton>
              <KeyboardArrowUpIcon />
            </IconButton>
          ) : (
            <IconButton>
              <KeyboardArrowDownIcon />
            </IconButton>
          )}
        </button>
      </div>
      <div className={`${styles.outlet} ${show ? "" : styles.show}`}>
        <Outlet />
      </div>
    </div>
  );
}
