import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  Paper,
  CircularProgress
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import api from "../Api";
import logoImage from "./YSR logo.jpeg";
import backgroundImage from "./background.svg";

const AdminLoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirecting, setRedirecting] = useState(false); // New state for redirection
  const navigate = useNavigate();

  const handleSignIn = async () => {
    setLoading(true);
    setRedirecting(true);

    try {
      const response = await api.adminLogin(email, password);
      const token = response.token;
      localStorage.setItem("access", token.accessToken);
      localStorage.setItem("refresh", token.refreshToken);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token.accessToken}`;
      navigate("/dashboard");
      window.location.reload();
    } catch (error) {
      console.error("Sign in error", error);
      setMessage("Invalid email or password. Please try again.");
      setLoading(false);
      setRedirecting(false);
    }
  };

  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Grid
        container
        component={Paper}
        elevation={3}
        sx={{
          borderRadius: 2,
          overflow: "hidden",
          height: "100%",
          width: "100%"
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 4
          }}
        >
          <img
            src={logoImage}
            alt="Logo"
            style={{ width: "100px", marginBottom: "1rem" }}
          />
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold"
            }}
          >
            Hi, Welcome Back!
          </Typography>
          <Box sx={{ width: "80%", mt: 2 }}>
            <Typography variant="body2" align="center" sx={{ my: 2 }}>
              Please Sign in to continue
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Email"
              autoComplete="off"
              InputProps={{
                style: { textAlign: "center" }
              }}
            />
            <TextField
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              autoComplete="off"
              InputProps={{
                style: { textAlign: "center" }
              }}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                mt: 2,
                padding: "8px 32px",
                fontSize: "0.875rem",
                borderRadius: "4px"
              }}
              onClick={handleSignIn}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Sign In"
              )}
            </Button>

            {message && (
              <Typography
                variant="body2"
                color="error"
                align="center"
                sx={{ mt: 2 }}
              >
                {message}
              </Typography>
            )}
            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              I agree to abide by the
              <Link
                to="/ysr-terms-and-conditions"
                style={{ textDecoration: "underline" }}
              >
                {" "}
                Terms of Service
              </Link>
              and its
              <Link
                to="/ysr-privecy-policy"
                style={{ textDecoration: "underline" }}
              >
                Privacy Policy
              </Link>
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={6}
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "100vh",
            width: "100vw"
          }}
        />
      </Grid>
    </Container>
  );
};

export default AdminLoginPage;
