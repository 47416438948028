import React, { useEffect, useState } from "react";
import styles from "../Dashboard/DashboardOverviews.module.css";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import api from "../../Api";
import icon1 from "../../Assets/todays/Icon (1).png";
import icon3 from "../../Assets/todays/Icon (3).png";
import { IosShare } from "@mui/icons-material";
import BarChart from "../Leaderboard/BarChart";
import walkInsIcon from "../../Assets/todays/Icon.png";
import CollecteDocsIcon from "../../Assets/todays/Icon (2).png";
import returnDocs from "../../Assets/todays/returnDocs.png";
import Performance from "../Leaderboard/Performance";

export default function DashboardOverviews() {
  const [data, setData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();

  const fetchCount = async () => {
    try {
      const response = await api.getOverviewCount("DASHBOARD");
      const output = response.data;
      setData(output);
    } catch (error) {}
  };

  useEffect(() => {
    fetchCount();
  }, []);

  const getPrice = (value) => {
    return value !== undefined && value !== null ? `${value}` : "0";
  };
  const contents = {
    dashboard: [
      {
        logo: walkInsIcon,
        price: getPrice(data?.totalWalkIns),
        title: "Total Walkins",
        profit: "+8% from yesterday",

        colorClass: "yellow"
      },
      {
        logo: CollecteDocsIcon,
        price: getPrice(data?.totalCollectedDocs),
        title: "Collected Docs",
        profit: "+8% from yesterday",

        colorClass: "green"
      },
      {
        logo: icon1,
        price: getPrice(data?.totalPendingLogIn),
        title: "Add Login Details",
        profit: "+8% from yesterday",

        colorClass: "blue"
      },
      {
        logo: icon3,
        price: getPrice(data?.totalLoggedIn),
        title: "Logged In Data",
        profit: "+8% from yesterday",

        colorClass: "pink"
      },
      {
        logo: returnDocs,
        price: 0,
        title: "Returned Docs",
        profit: "+8% from yesterday",

        colorClass: "purple"
      }
    ]
  };

  useEffect(() => {
    let currentIndex;
    switch (true) {
      case location.pathname.includes("/"):
        currentIndex = 0;
        break;
      default:
        currentIndex = null;
    }
    setActiveIndex(currentIndex);
  }, [location.pathname]);

  const handleBoxClick = (index) => {
    setActiveIndex(index);
  };

  const activeContent = contents[Object.keys(contents)[activeIndex]] || [];

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <div
            style={{
              padding: "0",
              height: "66px",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              marginLeft: "2rem"
            }}
          >
            <h2 className={styles.heading}>Today's Summary</h2>
            <h4 className={styles.subHead}>admissions</h4>
          </div>
          <div>
            <button className={styles.button}>
              <IosShare sx={{ textAlign: "left" }} />
              Export
            </button>
          </div>
        </div>

        <div className={styles.box}>
          {activeContent.map((content, index) => (
            <div
              className={`${styles.innerbox} ${styles[content.colorClass]} ${
                index === activeIndex ? styles.active : ""
              }`}
              key={content.title}
              onClick={() => handleBoxClick(index)}
            >
              <Link to={content.path} className={styles.link}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <div>
                    <img
                      src={content.logo}
                      className={styles.logo}
                      alt={content.title}
                    />
                  </div>
                </div>
                <div className={styles.price}>{content.price}</div>
                <div className={styles.title}>{content.title}</div>
                <div className={styles.profit}>{content.profit}</div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.outlet}>
        <div className={styles.customtable}>
          <Performance />
        </div>
        <div className={styles.barchart}>
          <BarChart />
        </div>
      </div>
    </div>
  );
}
